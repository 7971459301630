.inviteFormRowWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  min-height: 70px;

  &:first-child {
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    margin-top: 25px;
  }

  .fieldWrapper {
    flex-grow: 1;
    margin-bottom: 10px;
    margin-top: 5px;

    &:first-child {
      margin-right: 24px;
    }

    &:nth-child(3) {
      margin-left: 24px;
    }

    & input {
      height: 1.25em;
    }
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  gap: 8px;
  .tag {
    background-color: #EBECEE;
    border-radius: 4px;
    padding: 0 16px;
    height: 26px;
    line-height: 26px;
    font-size: 11px;
    font-weight: 700;
  }
}